<template>
  <main class="view">
    <div class="col col-12 tabs-wrapper">
      <ul class="tab-controller" :class="[!showTabs ? 'hidden' : '']" style="--submenu-bg: #1B577A;">
        <li v-if="IsMainCompany()" class="tab-controller__item" :class="[listsView == 0 ? 'active' : 'non', Check(AdminRoles) ? '--parent' : '']">
          <router-link v-if="IsMainCompany()" class="item-link" :to="{name: 'picker-stats'}">{{ $t('Pickers') }}</router-link>
          <ul class="item-submenu">
            <!--
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'lists-overview'}"> Overview </router-link>
            </li>
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'lists-approve'}"> Approve </router-link>
            </li>
            -->
          </ul>
        </li>
        <li class="tab-controller__item" :class="[listsView == 1 ? 'active' : 'non', Check(AdminRoles) ? '--parent' : '']">
          <router-link class="item-link" :to="{name: 'packer-stats'}">{{ $t('Packers') }}</router-link>
          <ul class="item-submenu"></ul>
        </li>
        <!--
        <li class="tab-controller__item" :class="[listsView == 2 ? 'active' : 'non', Check(AdminRoles) ? '--parent' : '']">
          <router-link class="item-link" :to="{ name: 'invoices'}"> Bulk invoicing </router-link>
          <ul class="item-submenu"></ul>
        </li>
        -->
      </ul>
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
  import { Permissions } from '@/helpers/Permissions';
  import { Config } from '@/helpers/Config';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'Statistics',
    mixins: [Permissions, Config, BPA],
    data() {
      return {
        dev: Config.dev,
        showTabs: true,
        listsView: 0,
        RouteMapper: {
          'picker-stats': 0,
          'packer-stats': 1
        },
        AdminRoles: [/*0, 21*/ 'admin', 'statistics_admin'],
        route: null
      }
    },
    created() {
      this.IsInRouteMapper();
    },
    mounted() {
      this.showTabs = this.tabs.length > 1;
    },
    watch: {
      $route() {
        this.IsInRouteMapper();
      }
    },
    computed: {
      tabs() {
        return document.querySelectorAll('.tab-controller__item');
      }
    },
    methods: {
      IsInRouteMapper() {
        this.route = this.$route.name;
        if (this.RouteMapper[this.route] !== null) {
          this.listsView = this.RouteMapper[this.route];
        }
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hidden {
    display: none;
  }
</style>